export const PHONE = 'phone';
export const TABLET = 'tablet';
export const LAPTOP = 'laptop';
export const DESKTOP = 'desktop';

export const GRID_MOBILE_DEVICES = [PHONE, TABLET];

export const GRID_DEVICE_COLUMNS = {
  [PHONE]: 8,
  [DESKTOP]: 24,
};

export const GRID_LAYOUT_DEFAULT = {
  rows: 4,
  rowHeight: 44,
  gap: 15,
  maxColumns: GRID_DEVICE_COLUMNS[DESKTOP],
  maxColumnsMobile: GRID_DEVICE_COLUMNS[PHONE],
};

export const GRID_COMPONENT_DEFAULT_COORDINATES = {
  rowStart: 0,
  columnStart: 0,
  width: 4, // in columns
  height: 4, // in rows
};

export const GRID_COMPONENT_DEFAULT_MOBILE_COORDINATES = {
  changed: false,
  rowStart: 0,
  columnStart: 0,
  width: GRID_LAYOUT_DEFAULT.maxColumnsMobile, // in columns
  height: 1, // in rows
};
