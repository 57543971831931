import {
  COUNTDOWN_ITEM_SIZE_LIMITS,
  TIMELINE_SIZE_LIMITS,
} from '@sp/structure-handle/src/fn/element/constants';

export const DEFAULT_FLUID = false;
export const DEFAULT_SIZE = 12;

export const DEFAULT = {
  SIZE: DEFAULT_SIZE,
};

/**
 * @typedef {Object} NAMES
 * @type {Object.<string, string>}
 */

export const NAMES = {
  // Sticky elements
  BACK_TO_TOP: 'back_to_top',
  PROMOTION: 'promotion',
  TEXT: 'text',
  HEADING: 'heading',
  BLOCK: 'row_block',
  BLOCK_NEW: 'block',
  BUTTON: 'button',
  BUTTON_DIV: 'button/div',
  BUTTON_DRAG: 'button/drag',
  DIVIDER: 'divider',
  FACEBOOK_COMMENTS: 'facebook_comments',
  FACEBOOK_LIKE: 'facebook_like',
  FACEBOOK_PAGE_WIDGET: 'facebook_page',
  FORM: 'form',
  TABLE: 'table',
  TABLE_COLUMN: 'table/columns',
  TABLE_ROW: 'table/row',
  TABLE_CELL: 'cell',
  TABLE_HEAD_CELL: 't/head', // old structure
  TABLE_STANDARD_CELL: 't/cell', // old structure
  GALLERY: 'gallery',
  HEADER: 'header',
  HEADER_BUTTON: 'header_button',
  ICON: 'icon',
  IMAGE: 'image',
  INSTAGRAM: 'instagram',
  ITEM: 'row_item',
  LOGO: 'logo',
  MAP: 'map',
  NAVIGATION: 'navigation',
  REDACTOR: 'redactor',
  REDACTOR_SLATE: 'redactor_slate',
  SECTION: 'builder/default',
  SECTION_NEW: 'section',
  SHAPE: 'shape',
  SLIDE: 'slide',
  SLIDER: 'slider',
  SLIDER_BG: 'slider_bg',
  SOCIAL: 'social',
  SOCIAL_SHARES: 'social_shares',
  SPACE: 'space',
  EMBED_BLOCK: 'embed_block',
  SLOGAN: 'slogan',
  SSL_SEAL: 'ssl_seal',
  PROGRESS_BAR: 'progress_bar',
  COUNTDOWN: 'countdown',
  COUNTDOWN_ITEM: 'countdown_item',

  // Business
  PAYPAL_PRODUCT: 'paypal_product',
  PAYPAL_PRODUCT_IMAGE: 'paypal_product_image',

  // Blog
  BLOG_POSTS_LIST: 'blog_category',
  BLOG_POST: 'blog_post',

  ECOMMERCE_CATALOGUE: 'ecommerce_catalogue',
  ECOMMERCE_PRODUCT_PAGE: 'ecommerce_product_page',
  ECOMMERCE_CART_PAGE: 'ecommerce_cart_page',
  ECOMMERCE_SHIPPING_PAGE: 'ecommerce_shipping_page',

  // Ecommerce dnd containers
  ECOMMERCE_LIST: 'ecommerce_list',
  ECOMMERCE_PRODUCT: 'ecommerce_product',
  ECOMMERCE_PROXY_PRODUCT: 'ecommerce_proxy_product',
  ECOMMERCE_PROXY_CATALOGUE: 'ecommerce_proxy_catalogue',

  // Ecommerce dnd components
  ECOMMERCE_OPTIONS: 'ecommerce_options',
  ECOMMERCE_PHOTO: 'ecommerce_photo',
  ECOMMERCE_TEXT: 'ecommerce_text',
  ECOMMERCE_BUTTON: 'ecommerce_button',

  // Ecommerce components
  // ECOMMERCE_RADIO: 'ecommerce_radio',
  ECOMMERCE_SELECT: 'ecommerce_select',
  // ECOMMERCE_CHECKBOX: 'ecommerce_checkbox',
  // ECOMMERCE_DATE: 'ecommerce_date',

  SHOPPING_CART: 'shopping_cart',

  TEMPLATE_HEADER: 'template_header',
  TWITTER_POST: 'twitter_post',
  TWITTER_TIMELINE: 'twitter_timeline',
  VIDEO: 'video',
  TIMELINE: 'timeline',
  TIMELINE_ITEM: 'timeline_item',
  TIMELINE_IMAGE: 'timeline_image',

  BOOKING_CATALOGUE: 'booking_catalogue',
  BOOKING_PRODUCT: 'booking_product',
};
/**
 * NAMES.SLIDE is exclude and adding on fn/filter/slide
 * @typedef {String[]} ELEMENTS
 */
export const ELEMENTS = [
  NAMES.FACEBOOK_LIKE,
  NAMES.FACEBOOK_PAGE_WIDGET,
  NAMES.FACEBOOK_COMMENTS,
  NAMES.FORM,
  NAMES.GALLERY,
  NAMES.ICON,
  NAMES.IMAGE,
  NAMES.INSTAGRAM,
  NAMES.MAP,
  NAMES.TABLE,
  NAMES.REDACTOR,
  NAMES.SHAPE,
  NAMES.SLIDER,
  NAMES.SLIDER_BG,
  NAMES.SOCIAL,
  NAMES.SOCIAL_SHARES,
  NAMES.SPACE,
  NAMES.DIVIDER,
  NAMES.TWITTER_POST,
  NAMES.EMBED_BLOCK,
  NAMES.TWITTER_TIMELINE,
  NAMES.VIDEO,
  NAMES.ECOMMERCE_PRODUCT,
  NAMES.ECOMMERCE_CATALOGUE,
  NAMES.BLOG_POSTS_LIST,
  NAMES.BLOG_POST,
  NAMES.SLOGAN,
  NAMES.SSL_SEAL,
  NAMES.PROGRESS_BAR,
  NAMES.BOOKING_PRODUCT,
  NAMES.BOOKING_CATALOGUE,
  NAMES.COUNTDOWN,
  NAMES.COUNTDOWN_ITEM,
  NAMES.TIMELINE,
  NAMES.PAYPAL_PRODUCT,
  NAMES.PAYPAL_PRODUCT_IMAGE,
];

export const ELEMENTS_WITH_PARENT = [NAMES.BUTTON];
export const ALL_ELEMENTS = [...ELEMENTS, NAMES.BUTTON];

/**
 *
 * @typedef {Object} ELEMENTS_WITH_CHILD
 */
export const ELEMENT_WITH_CHILD = {
  'button/div': 'button',
};

/**
 *
 * @typedef {Array<string>} ELEMENTS_WITH_CHILDREN
 */
export const ELEMENTS_WITH_CHILDREN = [
  NAMES.BUTTON_DIV,
  NAMES.ECOMMERCE_PRODUCT,
];

/**
 *
 * @typedef {Object} PARENTS
 */
export const PARENTS = [
  NAMES.SHAPE,
  NAMES.SLIDER,
  NAMES.SLIDER_BG,
  NAMES.SLIDE,
];

export const STATUS = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
  INSERT: 'insert',
  REMOVE: 'remove',
  UPDATE: 'update',
};
export const STATUS_VALUES = Object.values(STATUS);

export const CLASSES = {
  HIDE: 'drag__original',
  CLONE: 'drag__clone',
  DRAG: {
    [STATUS.INSERT]: 'insert',
    [STATUS.LEFT]: 'insert_left',
    [STATUS.RIGHT]: 'insert_right',
    [STATUS.TOP]: 'insert_top',
    [STATUS.BOTTOM]: 'insert_bottom',
  },
  EMPTY_ROW: 'row-empty',
  EMPTY_BLOCK: 'row-empty__item',
  DISABLE_BLOCK: 'row-empty__item_disabled',
  SHOW_AREA: 'show',
  HIGHLIGHT_DRAG: 'section__drag-active',
  HIGHLIGHT_EMPTY: 'section__drag-empty',
};

export const HEADER_ELEMENTS_PRIORITIES = new Map([
  ['burger', 1],
  [NAMES.HEADER_BUTTON, 2],
  [NAMES.SHOPPING_CART, 3],
  [NAMES.SOCIAL, 4],
  [NAMES.SLOGAN, 5],
  [NAMES.LOGO, 6],
  [NAMES.NAVIGATION, 7],
]);

export const HEADER_COLLAPSED_PRIORITIES = new Map([
  ['burger', 1],
  [NAMES.SLOGAN, 2],
  [NAMES.HEADER_BUTTON, 3],
  [NAMES.SOCIAL, 4],
  [NAMES.NAVIGATION, 5],
  [NAMES.SHOPPING_CART, 6],
  [NAMES.LOGO, 7],
]);

export const ELEMENTS_SIZE_LIMITS = {
  [NAMES.COUNTDOWN]: COUNTDOWN_ITEM_SIZE_LIMITS,
  [NAMES.TIMELINE]: TIMELINE_SIZE_LIMITS,
};
