import { AUTH_METHODS } from '../constants';

import getAccessToken from './getAccessToken';

/**
 * Get query params for fetch request
 * @param {String=} method
 * @param {Object=} params
 * @returns {Omit<{}, "withoutAccess">}
 */
const getQueryParams = (method, params) => {
  const { withoutAccess, withBearer, ...rest } = params || {};
  const isNeedAddToken = !withoutAccess && !AUTH_METHODS.has(method)
                          && !withBearer;

  return {
    ...(isNeedAddToken) && {
      accessToken: getAccessToken(),
    },
    ...(method === 'GET' && { timestamp: Date.now() }),
    ...rest,
  };
};

export default getQueryParams;
